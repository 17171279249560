import React from 'react';
import Header from '../components/header/Header';
import { Chain, CHAINS, CurrentChain } from '../config/chains';
import { Provider, TransactionReceipt } from 'ethers';
import { Button, Modal } from 'react-bootstrap';
import InformationIcon from '../assets/icon/information.png';
import { connect, isInWeb3Browser, onAccountChange, onChainSwitch, switchNetwork, toEIP55, tryConnectSilent } from '../utils/dapp';
import { Project } from '../components/project/ProjectCard';
import "./MinePage.css"
import NFTList from '../components/nfts/NFTList';
import Footer from '../components/footer/Footer';
import ProjectList from '../components/project/ProjectList';
import { API } from '../config/api';

import EmptyImage from '../assets/image/empty.png';

interface MinePageProps {
    tab?: string | null;
}

interface MinePageState {
    chain?: Chain;
    address?: string;
    provider?: Provider;
    blockNumber?: number;
    showAlert?: boolean;
    alertMessage?: string;
    tabs: Array<string>;
    tab: number;
    nfts: Array<bigint | number>;
    page: number;
    size: number;
    total: number;
    projects: Array<Project>;
    showTxResult: boolean;
    txHash?: string;
    txReceipt?: TransactionReceipt;
    loading: boolean;

}

class MinePage extends React.Component<MinePageProps, MinePageState> {

    constructor(props: MinePageProps) {
        super(props);
        this.state = {
            chain: undefined,
            address: undefined,
            provider: undefined,
            blockNumber: undefined,
            showAlert: false,
            alertMessage: '',
            tabs: ['Minted', 'Created', 'Locked LP'],
            tab: props.tab ? Number(props.tab) : 0,

            nfts: [],

            page: 0,
            size: 9,
            total: 0,
            projects: [],

            showTxResult: false,
            txHash: undefined,
            txReceipt: undefined,

            loading: false,
        };
    }

    componentDidMount(): void {
        this.initSilent();
    }

    componentDidUpdate(prevProps: Readonly<MinePageProps>, prevState: Readonly<MinePageState>, snapshot?: any): void {
        if (prevState.address !== this.state.address) {
            this.loadProjects(this.state.tab, this.state.page);
        }
    }

    init = async () => {
        // try connect with popup
        if (!isInWeb3Browser()) {
            console.error('not in web3 browser');
            this.alert('Please use a web3 browser');
            return;
        }

        const res = await connect();
        if (res) {
            const chain = CHAINS[Number(res.chainId)];
            if (!chain) {
                console.error('chain not supported', res.chainId);
                // this.alert('Chain not supported');
                switchNetwork(CurrentChain, () => {
                    this.init()
                });
                return;
            }

            this.setState({
                provider: res.provider,
                blockNumber: res.blocknumber,
                chain: chain,
                address: res.address,
            });

            this.watchChange();
        }
    }

    watchChange = async (): Promise<void> => {
        onAccountChange((accounts: any) => {
            this.setState({
                address: toEIP55(accounts[0])
            })
        })

        onChainSwitch((chainId: number | string) => {
            console.log('chainId:', chainId)
            this.setState({
                chain: CHAINS[Number(chainId)],
            });
            this.initSilent();
            if (CHAINS[Number(chainId)] === undefined) {
                // not support
                alert('Chain not supported:' + chainId)
                return;
            }
        })
    }

    initSilent = async (): Promise<void> => {
        if (!isInWeb3Browser()) {
            return;
        }
        // const result = await conn();
        const result = await tryConnectSilent();
        console.log('result = ', result)
        if (result === null) {
            return;
        }

        const { chainId, provider } = result;
        const numChainId = Number(chainId);

        if (CHAINS[numChainId] === undefined) {
            return;
        }

        const blockNumber = await provider.getBlockNumber();

        this.setState({
            chain: CHAINS[numChainId],
            provider: provider,
            blockNumber: blockNumber,
        });

        if (result.address !== undefined) {
            this.setState({
                address: toEIP55(result.address),
            });
        }

        console.log('conn block number:', blockNumber)
        this.initTimer(CHAINS[numChainId], provider);
        this.watchChange();
    }

    initTimer = (chain: any, provider: Provider): void => {
        const that = this
        provider.on('block', async (blockNumber: number) => {
            that.setState({
                blockNumber: blockNumber,
            });
        })
    }

    loadProjects = async (tab: number, page: number): Promise<void> => {
        this.setState({
            projects: [],
        });
        const url =
            tab === 1 ? `${API}/project/list/${this.state.address}?page=${page}&size=${this.state.size}`
                : tab === 0 ? `${API}/event/address/v2/${this.state.address}?page=${page}&size=${this.state.size}&sort=project.createdAt,DESC` : undefined;

        if (!url) {
            return;
        }
        this.setState({
            loading: true,
        });

        try {
            const response = await fetch(url);
            const result = await response.json();

            if (result.code === 1) {

                this.setState({
                    total: result.data.totalElements,
                    projects: result.data.content,
                });
            } else {
                alert('Failed to load data');
            }

            this.setState({
                loading: false,
            });

        } catch (error) {
            console.error(error);
            this.setState({
                loading: false,
            });
        }

    }

    alert = (message: string): void => {
        this.setState({
            showAlert: true,
            alertMessage: message
        });
    }

    render() {
        const { tab } = this.state;
        return <div>
            <Header showMessage={false} chain={this.state.chain} onConnectClick={() => {
                this.init();
            }} address={this.state.address ?? ""} />
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '1rem',
            }}>
                <div className="mineTabs">
                    {this.state.tabs.map((tab, index) => {
                        return <div key={index} className={this.state.tab === index ? 'mineTab mineTabActive' : 'mineTab'} onClick={() => {
                            this.setState({
                                tab: index
                            });
                            this.loadProjects(index, 0);
                        }}>{tab}</div>;
                    })}
                </div>
                <div className="z3 pointer" style={{
                    padding: '0.5rem',
                    color: '#1BB2DA',
                }} onClick={() => {
                    window.location.href = '/factory';
                }} >
                    [ start a new coin! ]
                </div>
            </div>
            {tab === 0 ? <div className="myProjectList">
                {this.state.projects.length > 0 ? <ProjectList provider={this.state.provider}
                    page={this.state.page}
                    size={this.state.size}
                    total={this.state.total}
                    projects={this.state.projects}
                    onPageChange={(page: number) => {
                        this.setState({
                            page
                        });
                        this.loadProjects(0, page);
                    }}
                /> : <div style={{
                    marginTop: '5rem',
                    marginBottom: '5rem',
                }}>
                    <img src={EmptyImage} alt="empty" style={{ width: '10rem', margin: 'auto', display: 'block' }} />
                </div>}
            </div> : null}
            {tab === 1 ? <div className="myProjectList">
                <div className='z3 margin-bottom'>It takes approximately 1 minute for the token to be displayed after it is created.</div>
                {this.state.projects.length > 0 ? <ProjectList provider={this.state.provider}
                    page={this.state.page}
                    size={this.state.size}
                    total={this.state.total}
                    projects={this.state.projects}
                    onPageChange={(page: number) => {
                        this.setState({
                            page
                        });
                        this.loadProjects(1, page);
                    }}
                /> : <div style={{
                    marginTop: '5rem',
                    marginBottom: '5rem',
                }}>
                    <img src={EmptyImage} alt="empty" style={{ width: '10rem', margin: 'auto', display: 'block' }} />
                </div> }
            </div> : null}
            {tab === 2 ? <div>
                <NFTList provider={this.state.provider} address={this.state.address} />
            </div> : null}
            {/* show alert */}
            <Modal centered show={this.state.showAlert}>
                <Modal.Body style={{ textAlign: 'center', lineHeight: '3' }}>
                    <div><img src={InformationIcon} alt="info" style={{ width: '3rem', marginRight: '1rem' }} /></div>
                    <div>{this.state.alertMessage}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        this.setState({
                            showAlert: false
                        });
                    }}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Footer />
        </div>;
    }
}

export default MinePage;