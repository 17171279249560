import React from 'react';

import './ProjectCard.css';
import { Badge } from 'react-bootstrap';
import { Provider, ethers } from 'ethers';
import { IFairLaunchTokenABI } from '../../abi/IFairLaunchToken.abi';
import { marketCap } from '../../utils/swap';
import { CHAINS } from '../../config/chains';
import { API } from '../../config/api';
import { hashAvatar } from '../../utils/avatar';
import { formatTinyPrice } from '../../utils/format';

export interface Project {
    address: string,
    name: string,
    symbol: string,
    meta: string,
    totalSupply: bigint,
    hash: string,
    softCap: bigint,
    marketCap: bigint,
    liquidity: bigint,
    creator: string,
    rate: number,
    [propName: string]: any;
}

interface ProjectCardProps {
    project: Project;
    provider?: Provider | null;
    blocknumber?: number;
    blockSeconds?: number;
}

export interface ProjectMeta {
    [key: string]: any;
    image?: string;
    X?: string;
    youtube?: string;
    discord?: string,
    telegram?: string,
    gitbook?: string,
    website?: string,
    description?: string,
}

export interface ProjectMarketInfo {
    totalEthers: bigint;
    untilBlockNumber: number;
    fundBalanceOf?: bigint;
    started?: boolean;
    mightGet?: bigint;
    marketCap: bigint;
}

export interface ProjectMetaItem {
    trait_type: string;
    value: string;

}

interface ProjectCardState {
    meta?: ProjectMeta;
    market?: ProjectMarketInfo;
    blocknumber?: number;
}

class ProjectCard extends React.Component<ProjectCardProps, ProjectCardState> {

    constructor(props: ProjectCardProps) {
        super(props);
        this.state = {
            meta: this.parseMeta(props.project.meta) || undefined,
            market: undefined,
            blocknumber: props.blocknumber || 0,
        };
    }

    componentDidMount(): void {
        this.initChainData();
    }

    componentDidUpdate(prevProps: Readonly<ProjectCardProps>, prevState: Readonly<ProjectCardState>, snapshot?: any): void {
        if (prevProps.blocknumber !== this.props.blocknumber) {
            // this.initChainData();
            this.setState({
                blocknumber: this.props.blocknumber
            });
        }
        if (prevProps.provider === undefined) {
            this.initChainData();
        }
    }

    initRESTData = async (): Promise<void> => {
        const response = await fetch(`${API}/project/rpc?address=${this.props.project.address}`);
        const data = await response.json();
        if (data.code === 1) {
            // if data.data is string, parse it to json
            const info = typeof data.data === 'string' ? JSON.parse(data.data) : data.data;
            this.setState({
                market: {
                    totalEthers: BigInt(info.totalEthers),
                    untilBlockNumber: info.untilBlockNumber,
                    marketCap: BigInt(0),
                    started: info.started,
                },
                blocknumber: info.blocknumber
            });
        }
    }

    initChainData = async (): Promise<void> => {
        this.initRESTData();
        if (!this.props.provider) {
            console.error('provider is not set');
            return;
        }
        const network = await this.props.provider.getNetwork();
        const chain = CHAINS[Number(network.chainId)];

        if (!chain) {
            console.error('chain is not supported');
            return;
        }

        try {
            const contract = new ethers.Contract(this.props.project.address, IFairLaunchTokenABI, this.props.provider);
            const totalEthers = await contract.totalEthers();
            const untilBlockNumber = await contract.untilBlockNumber();

            const started = await contract.started();
            const market: ProjectMarketInfo = {
                totalEthers,
                untilBlockNumber,
                marketCap: BigInt(0),
                started
            };

            if (started) {
                if (chain !== undefined) {
                    try {
                        const theMarketCap = await marketCap(chain, this.props.project.address, this.props.provider);
                        market.marketCap = theMarketCap;
                    } catch (e) {
                        market.marketCap = BigInt(0);
                        console.error(e);
                    }
                } else {
                    market.marketCap = BigInt(0);
                }
            }

            this.setState({
                market
            });
        } catch (e) {
            this.initRESTData();
        }
    }

    toKMBT(num1: bigint | undefined, fixed: number = 3): string {
        if (num1 === undefined) {
            return '';
        }
        // console.log('num1', num1);
        const str: string = ethers.formatEther(BigInt(num1));
        const num = Number(str);
        if (num > 999 && num < 1000000) {
            return (num / 1000).toFixed(fixed) + 'K'; // convert to K for number from > 1000 < 1 million
        } else if (num >= 1000000 && num < 1000000000) {
            return (num / 1000000).toFixed(2) + 'M'; // convert to M for number from > 1 million
        } else if (num >= 1000000000) {
            return (num / 1000000000).toFixed(3) + 'B'; // convert to B for number from > 1 billion
        } else if (num <= 999) {
            return num.toFixed(fixed).toString(); // if value < 1000, nothing to do
        }
        return num.toString();
    }

    toHHMM(seconds: number): string {
        if (seconds < 0) {
            return '00:00';
        }
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - hours * 3600) / 60);
        // const _seconds = seconds - hours * 3600 - minutes * 60;

        return `${hours < 10 ? "0" + hours : hours}h ${minutes < 10 ? "0" + minutes : minutes}m` //:${_seconds < 10 ? "0" + _seconds : _seconds}`;
    }

    // parse project meta to ProjectMeta
    parseMeta(meta: string): ProjectMeta | undefined {
        let obj: Array<ProjectMetaItem> = [];
        try {
            // data:application/json;base64,
            const _meta = meta.split(',')[1];

            obj = JSON.parse(
                atob(_meta)
            );

            // iterator obj , each element is formatted [{'traid_name': 'image', 'value': 'https://xxx'}, {'traid_name': 'youtube', 'value': 'https://xxx']
            let result: ProjectMeta = {};

            obj.forEach((element: ProjectMetaItem) => {
                result[element.trait_type] = element.value;
            });

            return result;

        } catch (error) {
            console.error(error);
        }

        return undefined;
    }

    render(): React.ReactNode {
        const { project, blockSeconds } = this.props;
        const { market, blocknumber } = this.state;

        const symbol = process.env.REACT_APP_BASE_CURRENCY;

        return <div className="projectCard mobile-margin-top" onClick={() => {
            // document.location.href = `/detail?address=${project.address}`;
            document.location.href = `/dt?address=${project.address}`;
        }}>
            <div style={{ textAlign: 'center' }}>
                <img src={this.state.meta?.image} alt={this.props.project.name}
                    style={{
                        width: "8rem",
                        height: "8rem",
                    }}
                />
                <div style={{
                    marginTop: '0.5rem'
                }}>
                    <span role="img" aria-label="fire">🔥</span>
                    <i><span style={{
                        color: '#ffc107'
                    }}>{this.props.project.rate}</span></i>
                </div>
            </div>
            <div className="projectCardInfo">
                <div
                    className="projectCardTitle z4"
                >Created by
                    <img src={hashAvatar(this.props.project.creator)} alt="" style={{
                        width: '0.8rem',
                        height: '0.8rem',
                        marginRight: '0.2rem',
                        marginLeft: '0.5rem'
                    }} />
                    {
                        this.props.project.creator.substring(0, 6) + '...' + this.props.project.creator.substring(this.props.project.creator.length - 4)
                    }</div>
                <div className='z4'>
                    {market && market.started ? <span>
                        Price: <span style={{
                            color: '#ffc107'
                        }}>${
                                formatTinyPrice(ethers.formatEther(market.marketCap))
                            }</span>
                    </span> : null}
                </div>
                <div className='z4'>
                    {market && market.started ? <span>
                        Market cap: <span style={{
                            color: '#ffc107'
                        }}>${
                                this.toKMBT(market.marketCap * BigInt(this.props.project.totalSupply) / BigInt(1E18))
                            }</span>
                    </span> : null}
                    {market && !market.started ? <span>
                        Funds raised : <span style={{
                            color: '#ffc107'
                        }}>{
                                ethers.formatEther(market.totalEthers)
                            } {symbol}
                            {/* ({
                                this.state.market !== undefined ? (this.state.market.totalEthers * BigInt(100) / BigInt(project.softCap)).toString() : ''
                            }%) */}
                            </span>
                    </span> : null
                    }
                </div>
                {!market || !market.started ? <div className='z4'>
                    Participants: <span style={{
                        color: '#ffc107'
                    }}>{project.funders + ''}</span>
                </div> : null}
                {blocknumber && blocknumber > 0 && blockSeconds && market ? <div className="hightlight">
                    {Number(market.untilBlockNumber) - blocknumber > 0 ? <span>
                        <Badge bg="info" style={{
                            fontWeight: 'normal'
                        }}>Countdown: {
                                this.toHHMM((Number(market.untilBlockNumber) - blocknumber) * blockSeconds)
                            }</Badge>
                    </span> : market.started ? <span className='z4'>
                    </span> : <Badge bg="warning" style={{ fontWeight: 'normal' }}>Ready to launch</Badge>}
                </div> : null}

                <div className='z3'>
                    <b>{this.props.project.name} ({this.props.project.symbol})</b>:&nbsp;
                    {decodeURIComponent(this.state.meta?.description || '')}
                </div>
            </div>
        </div>
    }
}

export default ProjectCard;